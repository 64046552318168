#home {
    position: relative;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-image: linear-gradient(-25deg, #999999, #666666, #446b71);
    background-size: 5px 5px;
    width: 100%;
    z-index: 1;

    animation: bganimate 15s ease infinite;

    @keyframes bganimate {
        0%{
            background-position: 0 50%;
        }
        50%{
            background-position: 100% 50%;
        }
        100%{
            background-position: 0 50%;
        }
    }

    .app__wrapper {
        padding: 0;

        .copyright {
            display: none;
        }
    }
}

.app__header {
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;
    

    @media screen and (min-width: 2000px) {
        padding-top: 8rem;
    }

    @media screen and (max-width: 2000px) {
        flex-direction: column;
    }
    @media screen and (max-width: 450px) {
        padding: 6rem 1rem 2rem;
    }
}



.app__header-info {
    flex: 0.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;

    @media screen and (max-width: 2000px) {
        width: 100%;
        margin-right: 0rem;
    }
}

.app__header-badge {
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    z-index: 1;
    margin-top: 50px;
    position: absolute;
    margin-left: 100px;

    .badge-cmp {
        padding: 1rem 2rem;
        border: var(--white-color);
        border-radius: 15px;
        flex-direction: row;
        width: 100%;
        background: #fffcef;
        display: inline;

        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        @media screen and (max-width: 1200px) {
            padding: 1rem 1rem; 
            margin-left: -20%;
        }
    }

    .badge-cmp {
        flex-direction: column;
        margin-top: 3rem;
        
        p {
            text-transform: uppercase;
            text-align: left;
            font-size: 1.2rem;

            @media screen and (max-width: 1200px) {
                justify-content: flex-start;
                align-items: flex-start;
                font-size: .9rem;   
            }
        }

        h1 {
            width: 100%;
            text-align: left;

            @media screen and (max-width: 1200px) {
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 1.9rem;   
            }
        }
    }

    @media screen and (max-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
        font-size: .4rem;
        
    }
    @media screen and (min-width: 1200px) {
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 150px;
        position: absolute;
        margin-left: 25px;
    }
}

.app__header-circles {
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 2rem;

    div {
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: var(--white-color);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

        img {
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1) {
        width: 100px;
        height: 100px;
    }
    div:nth-child(2) {
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }
    div:nth-child(3) {
        width: 70px;
        height: 70px;
    }

    @media screen and (min-width: 2000px) {
        div:nth-child(1) {
            width: 300px;
            height: 300px;
        }
        div:nth-child(2) {
            margin: 170px;
            height: 170px;
        }
        div:nth-child(3) {
            width: 100px;
            height: 100px;
        }
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div {
            margin: 1rem;
        }
    }
}

.app__header-img {
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: relative;

    
    
    
    :nth-child(1) img {
        width: 100%;
        height: 100%;
        margin-right: -195%;
        margin-bottom: -45%;
        z-index: 1;
        object-fit: contain; 
        filter: grayscale(100%) contrast(1) blur(5);
        mix-blend-mode: multiply;
        opacity: 1;
        }

    :nth-child(2) img {
        width: 90%;
        height: 90%;
        margin-left: -180%;
        margin-bottom: -35%;
        z-index: 1;
        object-fit: contain;
        filter: grayscale(100%) contrast(1) blur(5);
        mix-blend-mode:multiply;
        opacity: 1;
        }
    :nth-child(3) img {
        width: 150%;
        height: 150%;
        margin-left: -450%;
        margin-bottom: -68%;
        position:relative;
        z-index: 1;
        object-fit: contain;
    }

    @media screen and (max-width: 1200px) {
        :nth-child(1) img {
            margin-right: -305%;
            transition: all 0.5s ease-in-out;
        }
    
        :nth-child(2) img {
            margin-left: -110%;
            transition: all 0.5s ease-in-out;
        }
        :nth-child(3) img {
            margin-left: -300%;
            transition: all 0.3s ease-in-out;
        }
    }

    @media screen and (min-width: 1200px) {
        :nth-child(1) img {
            margin-right: -295%;
            transition: all 0.5s ease-in-out;
        }
    
        :nth-child(2) img {
            margin-left: -90%;
            transition: all 0.5s ease-in-out;
        }
        :nth-child(3) img {
            margin-left: -300%;
            transition: all 0.3s ease-in-out;
        }
    }
    

    .overlay_circle {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -20%;
        z-index: 0;
        width: 100%;
        height: 135%;
        transition: all 0.3s ease-in-out;
        filter: grayscale(100%) contrast(1) blur(5);
        mix-blend-mode:hard-light;
        opacity: .5;
        
        @media screen and (min-width: 2000px) {
            margin-left: 100px;
            transition: all 0.3s ease-in-out;    
        }

        @media screen and (min-width: 1200px) {
            margin-left: 40px;
            transition: all 0.3s ease-in-out;    
        }

        @media screen and (min-width: 400px) {
            margin-left: 10px;
            transition: all 0.3s ease-in-out;    
        }
        
    } 
}
