.intro {
    background: rgb(64, 64, 64);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 999;
    
}

.slider {
    background: #FFCC05;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    z-index: 998;
}

.intro__text {
    color: blanchedalmond;
    font-size: 3rem;
    z-index: 999;
    filter: grayscale(100%) contrast(1) blur(5);
}

.intro__hide {
    background:  rgb(64, 64, 64);
    overflow: hidden;

    z-index: 999;
    
    span {
    transform: translateY(100%);
    display: inline-block;
    }
}