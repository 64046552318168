@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,400;1,700&family=Open+Sans:ital,wght@0,400;0,500;0,600;1,400;1,500&family=Outfit:wght@200;500&family=Poppins:wght@300;400;500;600&display=swap');


:root {
  --font-base: 'Nunito Sans', sans-serif;

  --primary-color: #858585;
  --secondary-color: #FFCC05;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #575757;
  --brown-color: #4f4f4f;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}